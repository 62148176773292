import store from "./";
import { bus } from "@/main";
import { ConflictException } from "@/utils/exception";

class CartHandler {
  protected static store = "pro_order";

  public get cart(): pro.OrderDetail | undefined {
    const cart = store.get(CartHandler.store);
    if (cart) return cart as pro.OrderDetail;
    return undefined;
  }

  public get catalogId(): pro.Id | null {
    if (!this.cart || !this.cart.catalog) return null;
    return this.cart.catalog.id ?? null;
  }

  public get catalog(): pro.Catalog | undefined {
    const cart = this.cart;
    if (!cart || !cart.catalog) return undefined;
    return cart.catalog;
  }

  public get consignee(): pro.Address | undefined {
    const cart = this.cart;
    if (!cart || !cart.consignee) return undefined;
    return cart.consignee;
  }

  public get lines(): pro.Line[] {
    const cart = this.cart;
    if (!cart || !cart.lines) return [];
    return cart.lines;
  }

  public get lineCount(): number {
    return this.lines.length;
  }

  /**
   * Clears the Cart's contents
   */
  public clear(): void {
    store.delete(CartHandler.store);
    bus.$emit("cartUpdated");
  }

  /**
   * Checks if a Cart exists
   */
  public cartExists(): boolean {
    return typeof this.cart !== "undefined";
  }

  /**
   * Checks if a Catalog is the same as the Cart's Catalog
   */
  public isSameCatalog(catalog: pro.Catalog): boolean {
    if (!this.catalog) return false;
    return catalog.id == this.catalog.id;
  }

  /**
   * Creates a Cart
   */
  public createCart(catalog: pro.Catalog): void {
    this.saveCart({ catalog });
  }

  /**
   * Checks if a Line Item is already in the Cart
   */
  public isInCart(line: pro.Line): boolean {
    // must remove the observer
    line = Object.assign({}, line);
    return this.lines.some((el: pro.Line) => line.unit?.id === el.unit?.id);
  }

  /**
   * Adds a Line Item to the Cart
   */
  public addToCart(
    line: pro.Line,
    catalog: pro.Catalog,
    overwrite = false
  ): void {
    if (!this.cartExists()) {
      this.createCart(catalog);
    } else if (!this.isSameCatalog(catalog)) {
      if (overwrite) {
        this.createCart(catalog);
      } else {
        throw new ConflictException(
          "The cart includes items from another catalog."
        );
      }
    } else if (this.isInCart(line)) {
      this.updateLineInCart(line);
      return;
    }

    const lines = this.lines;
    lines.push(line);
    this.saveCart({ ...this.cart, lines });
  }

  /**
   * Removes a Line from the Cart
   */
  public removeFromCart(line: pro.Line): void {
    const lines = this.lines.filter(
      (el: pro.Line) => el.unit?.id != line.unit?.id
    );
    this.saveCart({ ...this.cart, lines });
    if (this.lineCount < 1) this.clear();
  }

  /**
   * Updates a Line in the Cart
   */
  public updateLineInCart(line: pro.Line): void {
    const index = this.lines.findIndex(
      (el: pro.Line) => el.unit?.id === line.unit?.id
    );
    const lines = this.lines;
    lines[index] = line;
    this.saveCart({ ...this.cart, lines });
  }

  /**
   * Updates the Cart consignee
   */
  public updateConsignee(consignee: pro.Address): void {
    this.saveCart({ ...this.cart, consignee });
  }

  /**
   * Update the confirmation message
   */
  public updateMessage(message?: string): void {
    if (message == "") message = undefined;
    this.saveCart({ ...this.cart, message });
  }

  /**
   * Update special order instructions
   */
  public updateInstructions(instructions?: string): void {
    if (instructions == "") instructions = undefined;
    this.saveCart({ ...this.cart, instructions });
  }

  public updateCC(cc?: string): void {
    if (cc == "") cc = undefined;
    this.saveCart({ ...this.cart, cc });
  }

  /**
   * Saves the Cart
   */
  protected saveCart(order: pro.OrderDetail): void {
    store.set(CartHandler.store, order);
    bus.$emit("cartUpdated");
  }
}

const cartHandler = new CartHandler();
export default cartHandler;
