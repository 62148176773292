







import { Component, Vue } from "vue-property-decorator";
import TheNavbar from "@/components/TheNavbar.vue";

@Component({ components: { TheNavbar } })
export default class App extends Vue {
  showHeader(): boolean {
    return this.authenticatedRoute();
  }

  authenticatedRoute(): boolean {
    return this.$route.meta.showHeader == true;
  }

  setViewHeight() {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }

  mounted() {
    this.setViewHeight();
  }
}
