import Fetch, { FetchRequestOptions, FetchResponse } from "./fetch";
import {
  currentUser,
  isSuperUser,
  logout,
  reauthenticate,
} from "./authentication";
import router from "@/router";
import {
  fatalErrorHandler,
  NotFoundException,
  notFoundHandler,
  PermissionDeniedException,
  UnauthorizedException,
} from "./exception";

export const STATES = {
  AK: "AK",
  AL: "AL",
  AR: "AR",
  AZ: "AZ",
  CA: "CA",
  CO: "CO",
  CT: "CT",
  DC: "DC",
  DE: "DE",
  FL: "FL",
  GA: "GA",
  HI: "HI",
  IA: "IA",
  ID: "ID",
  IL: "IL",
  IN: "IN",
  KS: "KS",
  KY: "KY",
  LA: "LA",
  MA: "MA",
  MD: "MD",
  ME: "ME",
  MI: "MI",
  MN: "MN",
  MO: "MO",
  MS: "MS",
  MT: "MT",
  NC: "NC",
  ND: "ND",
  NE: "NE",
  NH: "NH",
  NJ: "NJ",
  NM: "NM",
  NV: "NV",
  NY: "NY",
  OH: "OH",
  OK: "OK",
  OR: "OR",
  PA: "PA",
  PR: "PR",
  RI: "RI",
  SC: "SC",
  SD: "SD",
  TN: "TN",
  TX: "TX",
  UT: "UT",
  VA: "VA",
  VT: "VT",
  WA: "WA",
  WI: "WI",
  WV: "WV",
  WY: "WY",
};

export const CARRIERS = {
  FedEx: "FEDEX",
  UPS: "UPS",
  USPS: "USPS",
};

export interface SearchOptions {
  q?: string;
  filters?: unknown;
  orderBy?: string;
  limit?: number;
  offset?: number;
}

export interface SearchRequest {
  (options: SearchOptions, catalogId?: pro.Id): Promise<pro.SearchResults>;
}

export interface AddressValidationResponse {
  status: "verified" | "unverified" | "warning" | "error";
  originalAddress: pro.Address;
  matchedAddress: pro.Address | null;
  messages: string[];
}

async function requestHandler(
  req: keyof Fetch,
  path: string,
  options?: FetchRequestOptions
): Promise<FetchResponse> {
  const origin = process.env.VUE_APP_API_ORIGIN;
  const fetch = new Fetch({ baseURL: origin });
  try {
    return await fetch[req](path, {
      ...options,
      withCredentials: true,
    });
  } catch (err) {
    if (err instanceof UnauthorizedException) {
      try {
        await reauthenticate();
        return await requestHandler(req, path, options);
      } catch (err) {
        if (err instanceof UnauthorizedException) {
          logout();
          router.push({ name: "LOGIN" });
        } else throw err;
      }
    } else if (err instanceof NotFoundException) {
      notFoundHandler();
    } else if (err instanceof PermissionDeniedException) {
      fatalErrorHandler();
      // re-fetch the current user's catalog roles
      // then retry
      // if failed second try, trigger fatal error
    }
    throw err;
  }
}

async function search(
  path: string,
  options: SearchOptions
): Promise<pro.SearchResults> {
  const res = await requestHandler("get", path, {
    params: {
      ...(options.filters as object),
      ...(options.q && { q: `${options.q}*` }),
      ...(options.orderBy && { orderBy: options.orderBy }),
      ...(options.limit && { limit: options.limit }),
      ...(options.offset && { offset: options.offset }),
    },
  });
  const results = res.data as pro.SearchResults;
  if (results.q) results.q = results.q.replace("*", "");
  return results;
}

/**
 * Finds a User
 */
export async function findUser(): Promise<pro.User> {
  const res = await requestHandler("get", "/users");
  return res.data;
}

/**
 * Updates a User
 */
export async function updateUser(user: pro.User): Promise<void> {
  await requestHandler("patch", `/users/${user.id}`, { data: user });
}

/**
 * Searches for Addresses
 */
export async function searchAddresses(
  options: SearchOptions
): Promise<pro.SearchResults> {
  if (!isSuperUser()) {
    if (!options.filters) options.filters = {};
    (options.filters as { createdBy?: pro.Id }).createdBy = currentUser().id;
  }
  return search("/addresses", options);
}

/**
 * Deletes an Address
 */
export async function deleteAddress(address: pro.Address): Promise<void> {
  await requestHandler("delete", `/addresses/${address.id}`);
}

/**
 * Creates a Catalog
 */
export async function createCatalog(
  catalog: pro.Catalog
): Promise<pro.Catalog> {
  const res = await requestHandler("post", "/catalogs", { data: catalog });
  return res.data;
}

/**
 * Updates a Catalog
 */
export async function updateCatalog(catalog: pro.Catalog): Promise<void> {
  await requestHandler("patch", `/catalogs/${catalog.id}`, { data: catalog });
}

/**
 * Regenerate Catalog access code
 */
export async function regenerateCatalogAccessCode(
  catalog: pro.Catalog
): Promise<pro.Catalog> {
  const res = await requestHandler("put", `/catalogs/${catalog.id}`);
  return res.data as pro.Catalog;
}

/**
 * Finds a Catalog
 */
export async function findCatalog(catalog: pro.Catalog): Promise<pro.Catalog> {
  const res = await requestHandler("get", `/catalogs/${catalog.id}`);
  return res.data;
}

/**
 * Searches for Catalogs
 */
export async function searchCatalogs(
  options: SearchOptions
): Promise<pro.SearchResults> {
  if (!isSuperUser()) {
    if (!options.filters) options.filters = {};
    (options.filters as { userId?: pro.Id }).userId = currentUser().id;
  }
  return search("/catalogs", options);
}

/**
 * Attempts to add a Member to a Catalog
 * If the email address is not associated to a user, an invitation to join is
 * sent.
 */
export async function addMember(
  catalog: pro.Catalog,
  emailAddress: string
): Promise<number> {
  const res = await requestHandler("post", `/catalogs/${catalog.id}/members`, {
    data: { emailAddress },
  });
  return res.status;
}

/**
 * Adds the Current User to a Catalog using the Catalog's access code
 *
 * @param catalog
 */
export async function joinCatalog(catalog: pro.Catalog): Promise<void> {
  await requestHandler("post", "/members/", {
    data: catalog,
  });
}

/**
 * Update Member
 */
export async function updateMember(
  catalog: pro.Catalog,
  member: pro.Member
): Promise<void> {
  const catalogId = catalog.id;
  const memberId = member.user?.id;
  await requestHandler("put", `/catalogs/${catalogId}/members/${memberId}`, {
    data: { roles: member.roles },
  });
}

/**
 * Searches for Members
 */
export async function searchMembers(
  options: SearchOptions,
  catalogId: pro.Id
): Promise<pro.SearchResults> {
  return search(`/catalogs/${catalogId}/members`, options);
}

/**
 * Removes a Member
 */
export async function removeMember(
  catalog: pro.Catalog,
  member: pro.Member
): Promise<void> {
  const memberId = member.user?.id;
  await requestHandler("delete", `/catalogs/${catalog.id}/members/${memberId}`);
}

/**
 * Searches for Series
 */
export async function searchSeries(
  options: SearchOptions,
  catalogId: pro.Id
): Promise<pro.SearchResults> {
  return search(`/catalogs/${catalogId}/series`, options);
}

/**
 * Searches for Orders created by the Current User
 */
export async function myOrders(
  options: SearchOptions
): Promise<pro.SearchResults> {
  if (!isSuperUser()) {
    if (!options.filters) options.filters = {};
    (options.filters as { createdBy?: pro.Id }).createdBy = currentUser().id;
  }
  return search("/orders", options);
}

/**
 * Searches for Orders
 */
export async function searchOrders(
  options: SearchOptions,
  catalogId: pro.Id
): Promise<pro.SearchResults> {
  return search(`/catalogs/${catalogId}/orders`, options);
}

/**
 * Finds an Order
 */
export async function findOrder(order: pro.Order): Promise<pro.OrderDetail> {
  const orderId = order.id;
  const catalogId = order.catalog?.id;
  const res = await requestHandler(
    "get",
    `/catalogs/${catalogId}/orders/${orderId}`
  );
  return res.data;
}

/**
 * Finds a Series
 */
export async function findSeries(
  series: pro.Series
): Promise<pro.SeriesDetail> {
  const catalogId = series.catalog?.id;
  const res = await requestHandler(
    "get",
    `/catalogs/${catalogId}/series/${series.id}`
  );
  return res.data;
}

/**
 * Creates an Address
 */
export async function createAddress(
  address: pro.Address
): Promise<pro.Address> {
  address.createdBy = currentUser();
  const res = await requestHandler("post", "/addresses", {
    data: { ...address, createdBy: { id: currentUser().id } },
  });
  return res.data;
}

/**
 * Validates an Address
 */
export async function validateAddress(
  address: pro.Address
): Promise<AddressValidationResponse> {
  address.createdBy = currentUser();
  const res = await requestHandler("post", "/addresses/validate", {
    data: address,
  });
  return res.data;
}

/**
 * Finds an Address
 */
export async function findAddress(address: pro.Address): Promise<pro.Address> {
  const addressId = address.id;
  const res = await requestHandler("get", `/addresses/${addressId}`);
  return res.data;
}

/**
 * Create a Cancellation
 */
export async function createCancellation(
  lines: pro.Line[],
  catalog: pro.Catalog
): Promise<pro.Cancellation> {
  const catalogId = catalog.id;
  const res = await requestHandler(
    "post",
    `/catalogs/${catalogId}/cancellation`,
    { data: { lines, createdBy: { id: currentUser().id } } }
  );
  return res.data;
}

/**
 * Create a Order
 */
export async function createOrder(
  order: pro.OrderDetail
): Promise<pro.OrderDetail> {
  const catalogId = order.catalog?.id;
  if (order.cc) {
    order.cc = order.cc.replace(" ", "");
  }
  const res = await requestHandler("post", `/catalogs/${catalogId}/orders`, {
    data: order,
  });
  return res.data;
}

/**
 * Creates a Shipment
 */
export async function createShipment(
  shipment: pro.Shipment
): Promise<pro.Shipment> {
  const catalogId = shipment.catalog?.id;
  const res = await requestHandler("post", `/catalogs/${catalogId}/shipments`, {
    data: { ...shipment, createdBy: { id: currentUser().id } },
  });
  return res.data;
}

/**
 * Finds a Shipment
 */
export async function findShipment(
  shipment: pro.Shipment
): Promise<pro.Shipment> {
  const shipmentId = shipment.id;
  const catalogId = shipment.catalog?.id;
  const res = await requestHandler(
    "get",
    `/catalogs/${catalogId}/shipments/${shipmentId}`
  );
  return res.data;
}

/**
 * Creates a Series
 */
export async function createSeries(series: pro.Series): Promise<pro.Series> {
  const catalogId = series.catalog?.id;
  const res = await requestHandler("post", `/catalogs/${catalogId}/series`, {
    data: series,
  });
  return res.data;
}

/**
 * Update a Series
 */
export async function updateSeries(series: pro.Series): Promise<pro.Series> {
  const seriesId = series.id;
  const catalogId = series.catalog?.id;
  const res = await requestHandler(
    "patch",
    `/catalogs/${catalogId}/series/${seriesId}`,
    { data: series }
  );
  return res.data;
}

/**
 * Creates an Item
 */
export async function createItem(
  item: pro.Item,
  catalogId: pro.Id
): Promise<pro.Item> {
  const res = await requestHandler("post", `/catalogs/${catalogId}/items`, {
    data: item,
  });
  return res.data;
}

/**
 * Updates an Item
 */
export async function updateItem(
  item: pro.ItemDetail,
  catalogId: pro.Id
): Promise<void> {
  await requestHandler("patch", `/catalogs/${catalogId}/items/${item.id}`, {
    data: item,
  });
}

/**
 * Finds a Item
 */
export async function findItem(
  item: pro.Item,
  catalogId: pro.Id
): Promise<pro.ItemDetail> {
  const itemId = item.id;
  const res = await requestHandler(
    "get",
    `/catalogs/${catalogId}/items/${itemId}`
  );
  return res.data;
}

/**
 * Creates a Unit
 */
export async function createUnit(
  unit: pro.Unit,
  catalogId: pro.Id
): Promise<pro.Unit> {
  const res = await requestHandler("post", `/catalogs/${catalogId}/units`, {
    data: unit,
  });
  return res.data;
}

/**
 * Updates a Unit
 */
export async function updateUnit(
  unit: pro.Unit,
  catalogId: pro.Id
): Promise<void> {
  await requestHandler("patch", `/catalogs/${catalogId}/units/${unit.id}`, {
    data: unit,
  });
}
