import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import "@/utils/icons";
import "./assets/tailwind.css";

Vue.config.productionTip = false;

Vue.directive("focus", {
  // When the bound element is inserted into the DOM...
  inserted: function(el) {
    // Focus the element
    el.focus();
  },
});

export const CARRIERS = {
  FedEx: "FEDEX",
  UPS: "UPS",
  USPS: "USPS",
};

export const CATALOG_ROLES = {
  Admin: "ADMIN",
  Approver: "APPROVER",
  Marketer: "MARKETER",
  Requestor: "REQUESTOR",
  Shipper: "SHIPPER",
};

export const UOMS = {
  Bag: "BG",
  Box: "BX",
  Case: "CS",
  Dozen: "DZ",
  Each: "EA",
  Pack: "PK",
  Pair: "PR",
  Sleeve: "SL",
};

export const STATES = {
  AK: "AK",
  AL: "AL",
  AR: "AR",
  AZ: "AZ",
  CA: "CA",
  CO: "CO",
  CT: "CT",
  DC: "DC",
  DE: "DE",
  FL: "FL",
  GA: "GA",
  HI: "HI",
  IA: "IA",
  ID: "ID",
  IL: "IL",
  IN: "IN",
  KS: "KS",
  KY: "KY",
  LA: "LA",
  MA: "MA",
  MD: "MD",
  ME: "ME",
  MI: "MI",
  MN: "MN",
  MO: "MO",
  MS: "MS",
  MT: "MT",
  NC: "NC",
  ND: "ND",
  NE: "NE",
  NH: "NH",
  NJ: "NJ",
  NM: "NM",
  NV: "NV",
  NY: "NY",
  OH: "OH",
  OK: "OK",
  OR: "OR",
  PA: "PA",
  PR: "PR",
  RI: "RI",
  SC: "SC",
  SD: "SD",
  TN: "TN",
  TX: "TX",
  UT: "UT",
  VA: "VA",
  VT: "VT",
  WA: "WA",
  WI: "WI",
  WV: "WV",
  WY: "WY",
};

export const USER_ROLES = {
  Super: "SUPER",
};

export const bus = new Vue();

new Vue({
  router,
  render: h => h(App),
}).$mount("#app");
