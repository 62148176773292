const store = {
  get: (key: string): unknown => {
    const val = window.localStorage.getItem(key);
    if (!val) return;
    return JSON.parse(val);
  },

  set: (key: string, value: unknown) => {
    window.localStorage.setItem(key, JSON.stringify(value));
  },

  delete: (key: string): void => {
    window.localStorage.removeItem(key);
  },
};

export default store;
