import axios, { AxiosInstance } from "axios";
import {
  BadRequestException,
  ConflictException,
  InternalException,
  NotFoundException,
  PermissionDeniedException,
  UnauthorizedException,
} from "@/utils/exception";

export interface FetchRequestOptions {
  params?: unknown;
  data?: unknown;
  auth?: { username: string; password: string };
  withCredentials?: boolean;
}

export interface FetchResponse {
  // eslint-disable-next-line
  data: any;
  status: number;
}

interface InstanceOptions {
  baseURL?: string;
  headers?: unknown;
  timeout?: number;
}

type RequestMethod = "get" | "post" | "put" | "patch" | "delete";

export class FetchRequestException extends Error {}
export class FetchNoResponseException extends Error {}

export default class Fetch {
  protected _instance: AxiosInstance;

  constructor(options?: InstanceOptions) {
    this._instance = axios.create(options);
  }

  public async get(path: string, options?: FetchRequestOptions) {
    return this.fetch("get", path, options);
  }

  public async post(path: string, options?: FetchRequestOptions) {
    return this.fetch("post", path, options);
  }

  public async put(path: string, options?: FetchRequestOptions) {
    return this.fetch("put", path, options);
  }

  public async patch(path: string, options?: FetchRequestOptions) {
    return this.fetch("patch", path, options);
  }

  public async delete(path: string, options?: FetchRequestOptions) {
    return this.fetch("delete", path, options);
  }

  protected async fetch(
    method: RequestMethod,
    path: string,
    options?: FetchRequestOptions
  ): Promise<FetchResponse> {
    try {
      return await this._instance.request({
        method,
        url: path,
        ...options,
      });
    } catch (err) {
      if (err.response) {
        switch (err.response.status) {
          case 400:
            throw new BadRequestException(err.response.data.message);
          case 401:
            throw new UnauthorizedException(err.response.data.message);
          case 403:
            throw new PermissionDeniedException(err.response.data.message);
          case 404:
            throw new NotFoundException(err.response.data.message);
          case 409:
            throw new ConflictException(err.response.data.message);
          default:
            throw new InternalException("An unexpected error occurred");
        }
      } else if (err.request) {
        // a request was made but no response is received
        throw new InternalException("An unexpected error occurred");
      } else {
        // a request could not be made
        throw new InternalException("An unexpected error occurred");
      }
    }
  }
}
