import Vue from "vue";
import { isAuthenticated, isVerified } from "@/utils/authentication";
import VueRouter, { RouteConfig } from "vue-router";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "HOME",
    component: () => import("@/views/Home.vue"),
    meta: { requiresAuth: true, showHeader: true },
  },
  {
    path: "/catalogs/:catalogId",
    name: "CATALOG",
    component: () => import("@/views/catalogs/Catalog.vue"),
    meta: { requiresAuth: true, showHeader: true },
  },
  {
    path: "/catalogs/join/:accessCode",
    name: "CATALOG_JOIN",
    component: () => import("@/views/catalogs/Join.vue"),
    meta: { requiresAuth: true, showHeader: false },
  },
  {
    path: "/catalogs/:catalogId/members",
    name: "MEMBERS",
    component: () => import("@/views/catalogs/Members.vue"),
    meta: { requiresAuth: true, showHeader: true },
  },
  {
    path: "/catalogs/:catalogId/series/:seriesId",
    name: "SERIES",
    component: () => import("@/views/catalogs/Series.vue"),
    meta: { requiresAuth: true, showHeader: true },
  },
  {
    path: "/catalogs/:catalogId/series/:seriesId/item/:itemId",
    name: "ITEM",
    component: () => import("@/views/catalogs/Item.vue"),
    meta: { requiresAuth: true, showHeader: true },
  },
  {
    path: "/catalogs/:catalogId/orders/:orderId",
    name: "ORDER",
    component: () => import("@/views/orders/Order.vue"),
    meta: { requiresAuth: true, showHeader: true },
  },
  {
    path: "/catalogs/:catalogId/orders",
    name: "ORDERS",
    component: () => import("@/views/orders/Orders.vue"),
    meta: { requiresAuth: true, showHeader: true },
  },
  {
    path: "/orders/cart",
    name: "ORDER_CART",
    component: () => import("@/views/orders/Cart.vue"),
    meta: { requiresAuth: true, showHeader: true },
  },
  {
    path: "/orders/checkout",
    name: "ORDER_CHECKOUT",
    component: () => import("@/views/orders/Checkout.vue"),
    meta: { requiresAuth: true, showHeader: true },
  },
  {
    path: "/users/:userId",
    name: "PROFILE",
    component: () => import("@/views/users/Profile.vue"),
    meta: { requiresAuth: true, showHeader: true },
  },
  {
    path: "/users/:userId/addresses",
    name: "ADDRESS_BOOK",
    component: () => import("@/views/users/AddressBook.vue"),
    meta: { requiresAuth: true, showHeader: true },
  },
  {
    path: "/catalogs/:catalogId/shipments/:shipmentId",
    name: "SHIPMENT",
    component: () => import("@/views/shipments/Shipment.vue"),
    meta: { requiresAuth: true, showHeader: true },
  },
  {
    path: "/catalogs/:catalogId/shipments/",
    name: "SHIPMENT_CREATE",
    component: () => import("@/views/shipments/Create.vue"),
    meta: { requiresAuth: true, showHeader: true },
  },
  {
    path: "/login",
    name: "LOGIN",
    component: () => import("@/views/users/Login.vue"),
  },
  {
    path: "/join/:token?",
    name: "JOIN",
    component: () => import("@/views/users/Join.vue"),
  },
  {
    path: "/password/forgot",
    name: "FORGOT_PASSWORD",
    component: () => import("@/views/users/ForgotPassword.vue"),
  },
  {
    path: "/password/reset/:token",
    name: "PASSWORD_RESET",
    component: () => import("@/views/users/PasswordReset.vue"),
  },
  {
    path: "/verify/:token?",
    name: "VERIFY_EMAIL",
    component: () => import("@/views/users/VerifyEmail.vue"),
  },
  {
    path: "/error",
    name: "ERROR",
    component: () => import("@/views/Error.vue"),
  },
  {
    path: "/404",
    name: "NOT_FOUND",
    component: () => import("@/views/NotFound.vue"),
  },
  {
    path: "*",
    redirect: "/404",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, _from, next) => {
  if (to.meta.requiresAuth && !isAuthenticated()) {
    next({
      name: "LOGIN",
      ...(to.name !== "LOGIN" &&
        to.name !== "HOME" && { query: { redirect: to.fullPath } }),
    });
  } else if (to.meta.requiresAuth && !isVerified()) {
    next({ name: "VERIFY_EMAIL" });
  } else {
    next();
  }
});

export default router;
