import Fetch, { FetchRequestOptions } from "./fetch";
import { findUser } from "@/utils/api";
import store from "@/utils/store";
import { FatalError } from "@/utils/exception";

export const USER_ROLES = {
  Super: "SUPER",
};

const userStore = "pro_user";
const clientId = process.env.VUE_APP_API_CLIENT;

/**
 * Manages backend requests
 */
async function requestHandler(
  req: keyof Fetch,
  path: string,
  options?: FetchRequestOptions
): Promise<unknown> {
  const origin = process.env.VUE_APP_API_ORIGIN;
  const fetch = new Fetch({ baseURL: origin });
  const res = await fetch[req](path, {
    ...options,
    withCredentials: true,
  });
  return res.data;
}

/**
 * Synchronizes the Current User's information
 */
export async function syncUserProfile(): Promise<void> {
  const user = await findUser();
  store.set(userStore, user);
}

/**
 * Authenticates the User using an email address and password
 */
export async function authenticate(user: pro.User): Promise<void> {
  const data = {
    grant_type: "password", // eslint-disable-line
    username: user.emailAddress,
    password: user.password,
    client_id: clientId, // eslint-disable-line
  };
  await requestHandler("post", "/oauth/token", { data });
  await syncUserProfile();
}

/**
 * Logs the User out
 */
export async function logout(): Promise<void> {
  try {
    await requestHandler("delete", "/oauth/token");
  } finally {
    store.destroy();
  }
}

/**
 * Reauthenticates the User using a refresh token
 */
export async function reauthenticate(): Promise<void> {
  const data = {
    grant_type: "refresh_token", // eslint-disable-line
    client_id: clientId, // eslint-disable-line
  };
  try {
    await requestHandler("post", "/oauth/token", { data });
  } catch {
    await logout();
  }
}

/**
 * Requests a Password Reset Email
 */
export async function sendResetPasswordEmail(
  emailAddress: string
): Promise<void> {
  const data = { emailAddress, clientId };
  await requestHandler("post", "/users/passwords/tokens", { data });
}

/**
 * Resets a User password using a token
 */
export async function resetPassword(
  user: pro.User,
  token: string
): Promise<void> {
  await requestHandler("put", "/users/password", {
    data: { newPassword: user.password, token },
  });
}

/**
 * Registers a User
 */
export async function register(
  user: pro.User,
  token?: pro.Id
): Promise<pro.User> {
  if (token) {
    return requestHandler("put", `/users/${token}`, {
      data: user,
    }) as pro.User;
  } else {
    return requestHandler("post", "/users", { data: user }) as pro.User;
  }
}

/**
 * Fetches the Current User's information
 */
export function currentUser(): pro.User {
  const user = store.get(userStore) as pro.User | undefined;
  if (!user) throw new FatalError();
  return user;
}

/**
 * Checks if the User has been authenticated
 */
export function isAuthenticated(): boolean {
  return typeof store.get(userStore) !== "undefined";
}

/**
 * Checks if the User's email address has been verified
 */
export function isVerified(): boolean {
  const user = currentUser();
  if (!user || !user.emailVerified) return false;
  return user.emailVerified;
}

/**
 * Checks if the User is a Super User
 */
export function isSuperUser(): boolean {
  const user = currentUser();
  if (!user || !user.roles) return false;
  return user.roles.includes(USER_ROLES.Super);
}

/**
 * Checks if a User if the Current User
 */
export function isCurrentUser(user: pro.User): boolean {
  const current = currentUser();
  if (!user.id || !current || !current.id) return false;
  return current.id == user.id;
}

/**
 * Verifies a User's email address using a Token
 */
export async function verifyEmail(token: pro.Id): Promise<pro.User> {
  return requestHandler("put", `/users/verify/${token}`) as pro.User;
}

/**
 * Sends an email verification email
 */
export async function sendVerificationEmail(): Promise<void> {
  const user = currentUser();
  await requestHandler("post", `/users/${user.id}/verify`);
}

/**
 * Changes a known User's password
 */
export async function changeKnownPassword(
  password: string,
  newPassword: string
): Promise<void> {
  const user = currentUser();
  await requestHandler("put", `/users/${user.id}/password`, {
    data: {
      password,
      newPassword,
    },
  });
}
