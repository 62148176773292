import Vue from "vue";
import {
  FontAwesomeIcon,
  FontAwesomeLayers,
  FontAwesomeLayersText,
} from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";

import {
  faAddressBook,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faBook,
  faBoxOpen,
  faCheckCircle,
  faCircle,
  faEnvelope,
  faExclamationCircle,
  faExclamationTriangle,
  faExternalLinkAlt,
  faEye,
  faEyeSlash,
  faInfoCircle,
  faLock,
  faPencilAlt,
  faShoppingCart,
  faSignOutAlt,
  faSpinner,
  faStar,
  faSync,
  faTachometerAlt,
  faTag,
  faTags,
  faTimes,
  faTruck,
  faTruckLoading,
  faUser,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faAddressBook,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faBook,
  faBoxOpen,
  faCheckCircle,
  faCircle,
  faEnvelope,
  faExclamationCircle,
  faExclamationTriangle,
  faExternalLinkAlt,
  faEye,
  faEyeSlash,
  faInfoCircle,
  faLock,
  faPencilAlt,
  faShoppingCart,
  faSignOutAlt,
  faSpinner,
  faStar,
  faSync,
  faTachometerAlt,
  faTag,
  faTags,
  faTimes,
  faTruck,
  faTruckLoading,
  faUser,
  faUsers
);

Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.component("font-awesome-layers", FontAwesomeLayers);
Vue.component("font-awesome-layers-text", FontAwesomeLayersText);
