import router from "../router";

export async function notFoundHandler(): Promise<void> {
  await router.push({ name: "NOT_FOUND" });
}

export async function fatalErrorHandler(): Promise<void> {
  await router.push({ name: "ERROR" });
}

export class BadRequestException extends Error {}
export class InternalException extends Error {}
export class NotFoundException extends Error {}
export class PermissionDeniedException extends Error {}
export class UnauthorizedException extends Error {}
export class ConflictException extends Error {}
export class FatalError extends Error {
  constructor() {
    super();
    fatalErrorHandler();
  }
}
