
















































import { Component, Vue } from "vue-property-decorator";
import cartHandler from "@/store/cart";
import { currentUser } from "@/utils/authentication";
import { bus } from "@/main";

@Component({})
export default class TheNavbar extends Vue {
  lineCount = 0;
  currentUser: pro.User | null = null;

  async mounted() {
    this.getLineCount();
    bus.$on("cartUpdated", () => this.getLineCount());
    this.loadUser();
  }

  loadUser(): void {
    this.currentUser = currentUser();
  }

  getLineCount(): void {
    this.lineCount = cartHandler.lineCount;
  }
}
